<template>
  <v-container
    class="background"
    fluid
    fill-height
  >
    <default-modal
      v-if="showModal"
      :message="modalState.message"
      :action="modalState.action"
      @close="closeModal"
      @action="doAction"
    />
    <v-row class="left-right-container">
      <v-col class="left-area ma-4">
        <canvas
          ref="canvas"
          :style="{ width: '100%', height: '100%' }"
          @mousedown="startDrawing"
          @touchstart="startDrawing"
          @mouseup="stopDrawing"
          @touchend="stopDrawing"
          @mouseout="stopDrawing"
          @touchout="stopDrawing"
          @mousemove="draw"
          @touchmove="draw"
        >
        </canvas>
      </v-col>
      <v-col class="right-area ma-4">
        <v-row
          class="header"
          justify="space-around"
        >
          <img
            src="../../assets/image/icon/back.png"
            alt="뒤로가기"
            type="button"
            @click="cancelMapping"
          >
          <span
            class="btn-cancel"
            type="button"
            @click="cancelMapping"
          >&nbsp;&nbsp;취소</span>
          <v-spacer />
          <span
            class="btn-save"
            type="button"
            @click="saveMapping"
          >저장&nbsp;</span>
        </v-row>
        <v-row>
          <v-col>
            <input
              v-model="name"
              type="text"
              class="input-map-name"
              placeholder="지도명 입력"
            >
          </v-col>
        </v-row>
        <v-row>
          <span>
            확대 및 축소
          </span>
          <v-spacer />
          <v-col>
            <input
              v-model="scale"
              type="range"
              min="0.1"
              max="100.0"
              step="0.1"
              @change="setScale"
            >
          </v-col>
        </v-row>
        <v-spacer />
        <v-row>
          <span>
            선 굵기
          </span>
          <v-spacer />
          <v-col>
            <input
              v-model="lineWidth"
              type="range"
              min="0.1"
              max="50.0"
              step="0.1"
              @change="setLineWidth"
            >
          </v-col>
        </v-row>
        <v-spacer />
        <v-row>
          <span>
            선 색
          </span>
          <v-spacer />
          <button
            @click="setColor('#fefefe')"
            :style="{color: 'white', background: 'white', height: '50px', width: '50px', borderRadius: '25px'}"
          ></button>
          <v-spacer />
          <button
            @click="setColor('#cdcdcd')"
            :style="{color: 'white', background: 'gray', height: '50px', width: '50px', borderRadius: '25px'}"
          ></button>
          <v-spacer />
          <button
            @click="setColor('#000000')"
            :style="{color: 'white', background: 'black', height: '50px', width: '50px', borderRadius: '25px'}"
          ></button>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DefaultModal from '@/components/modals/default_modal.vue';

export default {
  name: 'MapEdit',
  components: {
    DefaultModal,
  },
  props: {
    mapId: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      showModal: false,
      modalState: {
        message: null,
        action: null
      },
      name: '',
      data: null,
      width: null,
      height: null,
      maxValue: null,
      drawing: false,
      context: null,
      lastX: 0,
      lastY: 0,
      lineWidth: 2.5,
      scale: 1,
    };
  },
  created () {
    this.initialize();
  },
  beforeDestroy(){
  },
  methods: {
    initialize() {
      return this.cancelMoving()
        .then(this.fetchData)
        .catch(err => { throw Error(err); });
    },
    fetchData() {
      return this.$axios.get(`/map/${this.mapId}`)
        .then(res => {
          if (res.status === 200) {
            this.data= res.data;
            if(this.data){
              this.name= this.data.map_name;
              this.width= this.data.width;
              this.height= this.data.height;
              this.maxValue= this.data.maxValue;
              this.setCanvas(this.width, this.height, this.data.data.data, this.maxValue);
            }
          }
        });
    },
    setCanvas(width, height, mapData, maxValue){
      const canvas= this.$refs.canvas;
      this.context= canvas.getContext('2d');

      const set = new Set(mapData);
      console.log(set);

      this.context.lineWidth= this.lineWidth;
      this.context.strokeStyle= '#cdcdcd';
      canvas.width= width;
      canvas.height= height;
      const img = this.context.getImageData(0, 0, width, height);
      for (let row = 0; row < (height-1); row++) {
        for (let col = 0; col < (width-1); col++) {
          const mapI = col + ((height - row) * width);
          const pos = ((row * width) + col) * 4;
          let data = mapData[mapI];
          img.data[pos] = data;
          img.data[pos + 1] = data;
          img.data[pos + 2] = data;
          img.data[pos + 3] = maxValue;
        }
      }
      this.context.putImageData(img, 0, 0);
      return canvas;
    },
    startDrawing(e){
      console.log('startDrawing');
      this.drawing= true;
      this.lastX= e.offsetX;
      this.lastY= e.offsetY;
    },
    draw(e){
      console.log('draw');
      const x= e.offsetX;
      const y= e.offsetY;
      if(!this.drawing){
        this.context.beginPath();
        this.context.lineTo(x, y);
      }
      else{
        this.context.lineTo(x, y);
        this.context.stroke();
      }
      /*
      if(!this.drawing) return;
      console.log('draw');
      this.context.beginPath();
      this.context.moveTo(this.lastX, this.lastY);
      this.context.lineTo(e.offsetX, e.offsetY);
      this.context.stroke();
      this.lastX= e.offsetX;
      this.lastY= e.offsetY;
      */
    },
    stopDrawing(){
      console.log('stopDrawing');
      this.drawing= false;
    },
    setColor(color){
      this.context.strokeStyle= color;
    },
    setLineWidth(){
      this.context.lineWidth= this.lineWidth;
    },
    setScale(){
      this.context.scale(this.scale, this.scale);
    },
    cancelMoving() {
      return this.$axios.put('/nav/cancel');
    },
    cancelMapping() {
      this.modalState.message = '맵 수정을 중단할까요?';
      this.modalState.action = '맵 수정 중단';
      this.showModal = true;
    },
    closeModal() {
      this.modalState.message = null;
      this.modalState.action = null;
      this.showModal = false;
    },
    saveMapping() {
      let isNew = true;
      if (this.name.length) {
        return this.$axios.get('/map/list')
          .then(res => {
            if (res.status === 200) {
              res.data.forEach(el => {
                if (this.mapId!== el.map_id&& el.map_name=== this.name) {
                  isNew = false;
                }
              });
              if (isNew) {
                this.modalState.message = '지도저장을 진행할까요?';
                this.modalState.action = '지도 저장';
                this.showModal = true;
              } else {
                this.$toast('중복된 지도명입니다');
              }
            }
          })
          .catch(err => { throw Error(err); });
      } else {
        this.$toast('지도명을 입력해 주세요');
      }
    },
    doAction() {
      if (this.modalState.action === '지도 저장') {
        this.modalState.message = '지도를 저장 중입니다';
        this.modalState.action = 'no-action';
        this.saveMap();
      }
      if(this.modalState.action === '맵 수정 중단'){
        this.$router.replace('/mapinfo');
      }
    },
    saveMap() {
      const img= this.context.getImageData(0, 0, this.width, this.height);
      const pgm= img.data.filter((element, index)=> index% 4=== 0);
      console.log('pgm:',pgm);
      return this.$axios.put(`/map/${this.mapId}`, {
          mapName: this.name,
          mapData: pgm,
      })
        .then(res => {
          if (res.status === 200) {
            this.$router.replace('/mapinfo');
          }
        })
        .catch(err => {
          this.closeModal();
          throw Error(err);
        });
    },
  }
}
</script>

<style scoped>
.background {
  overflow: hidden;
  background-color: #393939;
  word-break: keep-all;
  font-family: sans-serif;
  font-style: normal;
  font-weight: bold;
}

.background > .left-right-container {
  height: 100vh;
}

.btn-cancel {
  color: #ffffff;
  font-size: 80px !important;
}

.btn-save  {
  justify-self: right;
  color: #ffffff;
  font-size: 80px !important;
}

.input-map-name {
  width: 100%;
  height: 100px;
  margin: 4rem 0 0;
  padding: 20px;
  border: 2px solid #545861;
  background-color: black;
  font-size: 3rem;
  color: white;
}

.input-map-name:focus {
  outline: #9aa0ae solid;
}

@media (max-width: 1040px) {
  .btn-cancel {
    font-size: 40px !important;
  }

  .btn-save {
    font-size: 40px !important;
  }

  .header img {
    width: 60px;
    height: 60px;
  }
}

@media (max-width: 1024px) {
  .btn-cancel {
    font-size: 20px !important;
  }

  .btn-save {
    font-size: 20px !important;
  }

  .header img {
    width: 30px;
    height: 30px
  }

  .input-map-name {
    height: 60px;
    margin: 50px 0 0!important;
    font-size: 1rem;
  }

}
</style>
